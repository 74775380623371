import React from 'react'
import classes from './Logo.module.css'
import {Link} from 'react-router-dom'

const logo=(props)=>(
    <div className={classes.Logo}>
        <Link to="/">
        {/* <img src="https://drive.google.com/uc?id=1oXKUaEURXR3SNK0pT2xH0m07SfikMuhi" alt="Kshitij"/> */}
        <img src="https://drive.google.com/uc?id=1w_iXH6woFyMrtKx_LWeY4y_6pyyqDiWg"></img>
        </Link>
    </div>
)

export default logo