import React from "react";
import classes from "./Wow.module.css";
import {TitleComponent} from '../../Components/TileComp/Tile'

const Wow = (props) => {
  const clicked = (url) => {
    window.open(url);
  };

  const PhoneBox = (props) => {
    return (
      <div className={classes.box4}>
        <div className={classes.box41}>
          <img src={props.src} />
        </div>

        <div className={classes.box42}>
          <h1>{props.heading}</h1>
          <p>{props.desc}</p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <TitleComponent title="WOW BOX"/>
      <div className={classes.anim}>
        <img src="https://drive.google.com/uc?id=1KiiVkHm1NBV87-plEaDBZwSQZVCX9bki" />
      </div>
      <div className={classes.pp}>
        <div className={classes.main}>
          <p className={classes.yellowH}>Intro </p>
          <p className={classes.subHeading}>
            Designing the app that makes User Expierence - “ WOW”
          </p>
          <p>
            During my internship at Cart-geek , I got the apportunity of
            designing a mobile app from scratch.WOW BOX, a start-up business,
            needed an app to showcase the new and cool products of the emerging
            brands. They connected with us to help them do just that, together
            with, help to advance WOW BOX a step closer to becoming the
            long-established brand
          </p>
          <img src="https://drive.google.com/uc?id=1qDJA-vLoGHZkFFu_ongo8Lt0u6vZZGl5"
    
          />
          <p className={classes.yellowH}>Design Process </p>
          <p className={classes.subHeading}>
            Updated Double Diamond - Framework for innovation{" "}
          </p>

          <img 
          src="https://drive.google.com/uc?id=1h9fSDvCc0p-V1lNeys3U52SFB07nr1Fw" 
          style={{width:"600px",alignSelf:"center"}}
          />
          <p>
            We began with a sprint session, followed by setting-out for design
            thinking. Then, in no time, we found-out layout and navigation
            solutions by developing a mood board and creating lo-fi wireframes.
            We built prototypes, developed and evolved with the app with each
            passing day, and ultimately after adding recertification as a key
            feature, made the app one-of-a-kind, and all of us were as pleased
            as punch like a superhero saves masses in a movie
          </p>
          <p className={classes.subHeading}>Problem :</p>
          <p>
            15000+ FMCG Products launch in India every year & we’re solving the
            problem of efficient discovery at scale for them using the combined
            power of experiential marketing, data & technology.People would
            better trust products they’ve tried or those that have been
            recommended by their friends.
          </p>
          <p className={classes.subHeading}>Solution :</p>
          <p>
            We need to design an app in the way so we could target the young
            folk who have hard times juggling the assignments, and at the same
            time, reach out to those workers, going the daily grind, or for
            anyone who wants to discover something different, something notable
          </p>
          <p className={classes.yellowH}>How Might We</p>
          <p>
            We will focus on HMW method. HMW method or "How Might we method
            creates atmosphere to arrive at innovative solution by analysing
            existing challenges present in your product, service or initiative.
          </p>
          <p className={classes.subHeading}>
            How might we make customer aware of our application?
          </p>
          <p>
            By running social media campaign or google ads on the same genre of
            applications.
          </p>
          <p className={classes.subHeading}>
            How might we induce trust for online payment?
          </p>
          <p>We can introduce trusted 3rd party Online Payment Gateway.</p>
          <p className={classes.subHeading}>
            How might we attract more customers?
          </p>
          <p>
            Give relevant search result which all kind of customers needs. Make
            the platform easy to user.
          </p>
          <p className={classes.subHeading}>How might we verify customers? </p>
          <p>SMS OTP.</p>
          <p className={classes.subHeading}>
            How might we reduce complexity of the app?{" "}
          </p>
          <p>We can make design as simple as possible.</p>
          <p className={classes.yellowH}> Information Architecture </p>
          <p>
            People often use the words ''Information Architecture “ to mean the
            menus on websites or in apps, but that's not really correct. While
            menus are a part of IA, they're really only one part of it.
            Information architecture is all about organization of information in
            a clear and logical way. Such organization follows a clear purpose
            -helping users navigate complex sets of information.
          </p>
          <img
            className={classes.wireframe}
            src="https://drive.google.com/uc?id=1dJfxfLfIuXq-qdFARbYqDVGgyLT7dLoK"
          />
          <p className={classes.yellowH}>Wow Box Design System</p>
          <p className={classes.subHeading}>
            Created a design sytem for using elements in various screens
          </p>

          <div className={classes.box3}>
            <div className={classes.b1}>
              <img src="https://drive.google.com/uc?id=1ppgvebzuZ6gcslp4c5tehE9JbFqQZbYs" />
            </div>

            <div className={classes.b2}>
              <p>
                "The quick brown fox jumps over the lazy dog" is an
                English-language pangram—a sentence that contains all of the
                letters of the English alphabet. Owing to its brevity and
                coherence, it has become widely known.”
              </p>
            </div>
          </div>

          <p
            style={{
              fontWeight: "bold",
              fontSize: " 28px",
              lineHeight: " 42px",
              letterSpacing: "0.6em",
              color: "#1C1C1C",
            }}
          >
            COLORS
          </p>

          <div className={classes.box3}>
            <div className={classes.b1}>
              <img src="https://drive.google.com/uc?id=1gWMyvpFZupZlW59UdFeYho_hu5nDIW31" />
            </div>

            <div className={classes.b2}>
              <p>
                Orange sparks enthusiastic responses and energetic feelings
                within. But the orange color is itself a large family. We
                experimented with all of them and finally came up with a sweet
                and decent one which can be arrested by a pair of eyes easily.
              </p>
            </div>
          </div>

          <img src="https://drive.google.com/uc?id=1w2wAj4xqogby-XHKLmvFk9u_wXj-H_JM" />

          <img src="https://drive.google.com/uc?id=1ixgtoKXg9NzByhcRrXWAmo2E0OR0lzIX" />
          <p className={classes.yellowH}>Scenario and testing v1</p>
          <p className={classes.subHeading}>
            Before we did the testing, we gave the user the scenario:
          </p>
          <img src="https://drive.google.com/uc?id=1pyraBkT0BBKD0nyUI1TbXNiVggc3Qc_P" />
          <p className={classes.subHeading}>Creating an account using OTP</p>
          <p>
            There were too many steps to making an account .I tried to reduce
            the no of flows so that it would become easy for the users
          </p>
          <p className={classes.subHeading}>Adding items to the cart </p>
          <p>
            Users had a hard time adding items to the cart. They took the time
            to figure out the Add to cart button.
          </p>
          <p className={classes.subHeading}>The UI design</p>
          <p>
            After talking to our design mentor, we realized that there was
            something that we lacked, which was the UI design. There was no
            color consistency between the logo and the button color. Also, we
            should pay more attention to the position of the text and the font
            size.
          </p>
          <p className={classes.yellowH}>IDEATION V2</p>
          <p className={classes.subHeading}>
            Focus on the design looks <br /> <br />
            We tried to implement more UI components on our design, and these
            were our design results.
          </p>


          <PhoneBox
            src="https://drive.google.com/uc?id=1Q7iNbQYVMsef0A5kMUqgTEtXiDtn023Z"
            desc="In this design, I have  made the picture smaller balance with the font under the picture."
            heading="Set up an account and view the simple tutorial"
          />

          <PhoneBox
            src="https://drive.google.com/uc?id=1g5tSWbxdd1UvFDs9tza8TYPbu67Xf02D"
            desc="With the new deisgn users can easuly add items to their cart."
            heading="Home Page"
          />

          <PhoneBox
            src="https://drive.google.com/uc?id=1abXbkVdwajIw5yW9vpRimRR9zBSPALa8"
            desc="Added an option for descoveries where users can see what new products they have found and if interested can add them to their cart."
            heading="Discoveries"
          />


          <div>
          <p className={classes.yellowH}>Understanding the real WOW </p>
          <p>
            I dug deep, wholly understood their perception and vision, and built
            a user-friendly mobile app. We covered the large Indian audience
            with added features like personalization to build a connection with
            the user and instilled a mark of exclusivity. So that every user can
            feel special.{" "}
          </p>
          <p className={classes.yellowH}>What I learned from this project :</p>
          <p>
            There won’t always be a clear path. Sometimes, you’re going to run
            into a piece of your project you wouldn’t have even thought about.
            That’s because you truly don’t know what you don’t know, but it gets
            easier with time and practice.
            <br />
            <br />
            Having an “all star” product is great and definitely pushes you
            forward, but with any north star you have to know that things take
            time. You might have thought you could reach that star at the
            beginning, but only moving through the process will let you know if
            you can or not.
            <br />
            <br />
            The funny thing about UX and the humans you study when making a
            product is - people NEVER do what you expect. That’s why we do
            research and testing, so we can get down to what our users really
            need. So, when your original research showed one thing and then you
            tested and your solution wasn’t the best fit just remember you’re
            moving in the right direciton.
          </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wow;
