import React from 'react'
import ReactPlayer from 'react-player'
import classes from './Youtube.module.css'

const Youtube=(props)=>(

    <div className={classes.rp}>
        <ReactPlayer url={props.link} controls={true}/>
  
    </div>
)

export default Youtube