import React from 'react'
import classes from './CaseStudy.module.css'
import {Link, useHistory} from 'react-router-dom'

const style1={
    
    marginLeft:"10%"
}


const CaseStudy=(props)=>{
    var x=window.screen.width;
    const history=useHistory()

    const handler=()=>{
        history.push(props.to)
    }
      
 
    return (
    <div className={classes.CaseStudy}>
       
       <img className={(props.f=="1")?classes.img2:classes.img} src={props.src} 
            onClick={handler}
       />
      
        <div className={classes.box2} style={(props.f=="1")?((x>500)?style1:null):null}>
        <p className={classes.heading}>{props.title}</p>
        <p className={classes.heading2}>{props.subtitle}</p>
        <p className={classes.descr}>{props.description}</p>        
        <Link to={props.to}>
        <button className={classes.caseButton} >Visit CaseStudy</button>
        </Link>
        </div>
       
    </div>
    )
}

export default CaseStudy