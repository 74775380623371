import React from "react";
import classes from "./VRV.module.css";
import {TitleComponent} from '../../Components/TileComp/Tile'

const VRV = (props) => {

    const clicked=(url)=>{
        window.open(url)
    }

  return (
    <div>
      <TitleComponent title="VRV REDESIGN"/>
       <div className={classes.anim}>
        <img
          src="https://drive.google.com/uc?id=1uq21h2KxrifcTqC52GOYPQCQoTiGEQVI"
        />
      </div>
      <div className={classes.pp}>
      <div className={classes.main}>
        <p className={classes.yellowH}>About VRV </p>

        <p className={classes.subHeading}>
          The anime streaming website is so overwhelming so lets fix that.
        </p>

        <p>
          VRV is an over-the-top streaming service launched in November 2016
          owned by Otter Media, a subsidiary of AT&T's WarnerMedia. The service
          bundles together anime, speculative fiction and gaming related
          channels and the service targets these large fandoms.
          <br />
          <br />
          The purpose for doing this project was to show the design with better
          clarity, discoverability, and seamless experience in every feature to
          show the subscriber that VRV is worthy to have.We basically focused on
          conducting research, usability improvements, and re-working the user
          interface to better display available content.
        </p>

        <img src="https://drive.google.com/uc?id=1ri3sUH1rmp6ibvcDFOgKmWJd-9ulharp" />

        <p className={classes.yellowH}>UX Challenges</p>

        <p>
          Although VRV looks visually good its problem lies in organizing the
          content and the information on the homepage.The search option often
          takes too much time to deliver the results and thus the users are
          unable to get the full experience in discovering the content and
          purchasing the membership.
        </p>

        <p className={classes.subHeading}>
          What we need to do is to help the users get to their content quicker
          without presesnting them with too much content.
        </p>

        <div className={classes.midimg}>
          <img src="https://drive.google.com/uc?id=1ftikBzeAf2cbpEYdF3bwa5YTBKV14zk0" />
        </div>
        <p className={classes.yellowH}>User Research</p>
        <p>
          We spoke to 16 people that used streaming platforms to understand
          behavioral patterns while watching VRV versus the site they always
          use. 50% of participants were direct users, they enjoyed watching
          animated content. But to our surprise, all participants were new to
          VRV.The survey also shows that only 2 people out of 51 are familiar
          with VRV.
        </p>

        <p className={classes.subHeading}> Findings from Interviews :</p>
        <p>
          <li>
            Search Funcion is really important ,it is the first thing that
            people use when they find their shows .
          </li>
          <br />
          <li>
            So about 80 % of the people complained about category and scrolling
            time on the VRV.
          </li>
          <br />
          <li>
            Almost all the users had a hard time finding the next episode while
            watching .
          </li>
          <br />
          <li>85 % of the users use the website while straming on VRV.</li>
        </p>

        <p className={classes.subHeading}> What the interviews taught us </p>

        <p>
          Keep it simple: Most users found the homepage overwhelming and had
          difficulty finding content, they were also confused by how the content
          was curated{" "}
        </p>

        <p>
          {" "}
          Work less: Most users did not enjoy the amount of scrolling required,
          they expressed concern for the level of work needed to explore the
          site.{" "}
        </p>

        <p>
          Search first, wander later: There were unmet expectations of the
          search bar, users found the search ability to be too limited. Most
          users circled back to the homepage when their searches were not
          successful
        </p>

        <p className={classes.yellowH}>Competitive Analysis</p>
        <p>
          By taking our user’s pain points, we conducted a competitive analysis
          focusing on what other competitors have done right in comparison to
          our platform.We dug deeper into the 12 alternative platforms that our
          interviewees shared. These platforms included: Netflix, Apple TV,
          Crunchyroll, Youtube, Disney Plus, Prime Video, Hulu, Gogoanime,
          KissAnime, Curiosity Stream, Twitch, and Discovery+
        </p>
        <div className={classes.midimg}>
          <img src="https://drive.google.com/uc?id=16CK3w_lUQVLApwGDdAyv0d_xHzzsM7kF" />
        </div>
        <p className={classes.yellowH}>
          Implementing card sorting and red routes to analyze the opportunity
          and pain points
        </p>
        <p>
          As we have taken a large no of competitors and because of that the
          data that we have received is very big so we have utilize card sorting
          method to analyze the findings we got. We implemented card sorting to
          categorize similar positive and negative findings from our competitor.
          To keep in mind, we will use the positive findings as opportunity, and
          avoid the negative findings as pain points. The team utilized red
          route to prioritize user needs and build the most valuable feature
          based on pain points
        </p>

        <div className={classes.midimg}>
          <img src="https://drive.google.com/uc?id=1yG1yoQb-QeK1MiZXyoRySPk8dZGo3DAh" />

          <p>
            Implementing card sorting and red routes to analyze the opportunity
            and pain points
          </p>
        </div>
        <p className={classes.yellowH}>Solution using Design</p>
        <p>
          We began with making low fidelity designs to solve current problems.
          This low fidelity were designed based on each flow the user have to
          finish their goals. Afterwards, we were finished with the low
          fidelity. We have a built in design system. This design system uses
          the atomic design method.
        </p>

        <p className={classes.subHeading}>
          Filling the Gaps which were present in VRV
        </p>

        <div className={classes.midimg} >
          <img 
          // style={{height:"300px"}}
          src="https://drive.google.com/uc?id=1CggNHp0ovVxCk9m8kEz9ayYYy6WYLhvJ" />
          <p>Redesigned VRV homepage</p>
        </div>

        <p className={classes.subHeading}>Introduce Content Genres</p>

        <p>
          One of the main problem was that the users were able to find content
          if they were given it’s title, but when they wanted to look for
          something new to watch they would often turn to genres or rely on
          recommendations, both features do not currently exist in VRV
        </p>

        <div className={classes.midimg} >
          <img 
          src="https://drive.google.com/uc?id=1IgtrnmsemxG6FfZPIL33AhTllOddbvpq" />
        </div>
        <p className={classes.imageDesc} style={{fontSize:"13px",textAlign:"center",alignSelf:"center",color:"black"}}>
            Video showing user searching for “comedy”, “action”, and “romance”.Current search has no genres tagged, so it only displays show titles with related word
          </p>

        <p className={classes.subHeading}>Reducing Vertical Scrolling</p>

        <p>
          The current homepage design uses an “endless vertical scroll” to
          attract users to explore new content . This is an engaging way to
          interact with content, but it is too much work for the users.
        </p>

        <div className={classes.midimg} >
          <img
          src="https://drive.google.com/uc?id=1ByZV1WMaASFd9QS1kqEvlbQ8PIueowY0" />
          <p>Video showing user scrolling down homepage feed</p>
        </div>

        <div className={classes.midimg}>
          <button className={classes.button1}
             onClick={()=>clicked("https://www.figma.com/proto/aa7bNPkpELUr6O5DA8FxQY/VRV?page-id=0%3A1&node-id=101%3A3183&viewport=680%2C453%2C0.03125&scaling=min-zoom")}
          >
            <span>VIEW MY PROTOTYPE HERE</span>
          </button>
        </div>

        <p style={{textDecoration:"underline",color:"black",cursor:"pointer"}}
            onClick={()=>clicked("https://www.figma.com/proto/aa7bNPkpELUr6O5DA8FxQY/VRV-Design-Process?page-id=103%3A2&node-id=103%3A1798&viewport=536%2C399%2C0.07463154941797256&scaling=min-zoom")}
        >Protoype for mobile</p>

        <p className={classes.subHeading}>Reflections</p>

        <p>
          1. Do not fall in love with the first solution you come up with. Keep
          iterating. Sketch out even the ideas you think are unusual/odd.
          Sometimes unusual ideas can inspire you to come up with ideas for the
          final product.
          <br />
          <br />
          2. I know it can be super exciting to start off designing screens but
          it is vital to have your flows & wireframes/sketches ready or else
          you'll end up wasting alot of time. I wasted a lot of my time because
          I started off with the visual design and got stuck a lot along the
          way.
          <br />
          <br />
          3. I had a really great time working on this project.There is a need
          for future reserach like a smart recommendation system or rating
          system can be designed to improve the experience for the users.
        </p>
      </div>
      </div>
    </div>
  );
};

export default VRV;
