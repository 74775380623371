import classes from "./ChalkPiece.module.css";
import React from "react";
import {TitleComponent} from '../../Components/TileComp/Tile'


const ChalkPiece = (props) => {
  const clicked = () => {
    const url =
      "https://www.figma.com/proto/3LQJlhyLYZzIW3dKDWQQLp/Biomimcry-Classification?page-id=0%3A1&node-id=13%3A0&viewport=-1157%2C153%2C0.25&scaling=contain";
    window.open(url, "_blank");
  };
  const clicked2 = () => {
    const url =
      "https://drive.google.com/drive/folders/1acGYiIE4CbXrsnrOgwZ7JgCa4dZieneS?usp=sharing";
    window.open(url, "_blank");
  };

  return (
    <div>
      <TitleComponent title="ChalkPiece - Case Study"/>
      <div className={classes.header}>
      <img style={{width:"100%"}} src="https://drive.google.com/uc?id=1NI2jc5o3xIy3v0jHBbNlOrhTEfvEz-oY"/>
      </div>
      
    <div className={classes.main}>
     
      <div className={classes.chalk}>
        <h1>About</h1>
        <p>
          In a world of design where creating Human-centered products for making
          processes efficient to boost corporate profits is what everyone is
          concerned, Chalk Piece is a group of people trying to break out this
          never-ending convention by using design as a tool for solving Social
          problems. <br/><br/>
          If Design can solve complex Business problems, then Design
          can also be used to prepare the world to battle with the problems of
          pollution, education, inequality, and climate change by providing a
          more sustainable approach.
          <br />
          <br />
          As a SDG ambassador for Chalkpiece and a member of chalkpiece internal
          team <span style={{ fontWeight: "bold" }}>Chalklab</span> I was given
          the responsiblity of designing creative activities for kids so that we
          can teach the teenagers about different concepts of design through
          these activities.
        </p>
        <div className={classes.pic2}>
          <img src="https://drive.google.com/uc?id=10qTKHi-JULDMkkivXD92MebKhQwkLNKu" />
        </div>
        <h1>The Biomimicry Design Process</h1>
        <p>
          Biomimicry is a fascinating and novel topic of study for younger
          students.The problem was that how design a activity for kids that is
          easy to understand for them so that they easily grasp the concept of
          biomimicry.
        </p>

        <div className={classes.blackbox}>
          <p>
            How Might We <br />
            Design an easy to understand activity for kids.
          </p>
        </div>

        <p>
          After a little bit of brainstorming I discovered the concept of
          Biomimicry Taxonomy. The Biomimicry Taxonomy is a classification
          system to organize biological content . <br/><br/>
          The Taxonomy categorizes the
          different ways that organisms and natural systems meet functional
          challenges.I did not considered making activities for Biomimicry
          Natural Designs as my teammate has already made actiivities covering
          nature.
          <br />
          <br />
          To teach the children about taxonomy I designed various presentations
          which I presented to my mentor Ramya after which she finalised the
          one.To teach the children about classification I divided the
          classification activity it into various steps .
          <br />
          <br />
          Step-1 Who here has a pet? What type of pet do you have? <br />
          Step-2 Let's group these animals into different categories. <br />
          Step-3 After a few minutes, have the groups share their categories
          with the class. Example categories: big animals vs. small animals,
          animals that live inside vs. outside, etc.) <br />
        </p>

        <h1>Engineering Connection</h1>
        <p>
          Many types of engineers are directly involved with animals.
          Environmental engineers who work with chemical spills or habitat
          restoration need to know which animals might be affected by their
          efforts. <br/><br/>
          Through the ages, people have been inspired by animals and
          plants in the natural world. Biomimicry examples include the
          development of antibiotics and healing drugs, as well as the design of
          underwater sea vessels, airplanes, boats.
        </p>
        <h1>Why would an engineer care about animals?</h1>
        <p>
          Engineers work with animals in many different ways. Some engineers
          study specific animals in great detail and then imitate them to design
          a technology for use by humans. 
          <br/><br/>
          For example, chemical engineers look at sharks and how they defend themselves from bacteria in the ocean to
          create new antibiotics to keep humans healthy.
          <br />
          <br />
          Environmental engineers want to protect animal homes for all kinds of
          animals, from fish to elephants. They might be involved in environment
          and resource management, and design technologies to help restore a
          habitat that was destroyed by natural or human caused disasters. 
          <br/><br/>
          They might work on an environment that has been wrecked by a flood or
          tornado, or a river that was polluted by a factory. It is important
          that engineers learn as much as possible about the animals they are
          working to protect. 
          They must know what types of food each animal in
          that environment eats as well as its interaction with other animals
          and its ecosystem.
          <br />
          <br />
          One way that engineers study how animals interact in an ecosystem is
          by creating a model of it. A biodome is a model that is designed to
          represent a particular environment and the community of organisms that
          live there.
        </p>
        <div className={classes.pic2}>
          <button className={classes.button1} onClick={clicked}>
           <span> VIEW MY WORK HERE</span>
          </button>
        </div>
        <h1>Reflection:</h1>
        <p>
          It was really difficult try to design activities for the teenagers
          that they would be able to understand as we did not want to make
          something complex .
          <br /> <br />
          When working in a team. it is easily possible to get lost in different
          ideas. With this project, I learned that no idea is perfect until it's
          tried and tested. To concluded any discussion, always map back to the
          user needs and make sure we as designers are not lost in between.
        </p>
        <p
          style={{
            fontSize: "25px",
            lineHeight: "37px",
            textDecorationLine: "underline",
          }}
          onClick={clicked2}
          className={classes.lastButton}
        >
          See my other projects for Chalkpiece{" "}
        </p>
      </div>
    </div>
    </div>
  );
};

export default ChalkPiece;
