import './App.css';
import React,{useEffect,useState} from 'react'
import Aux from './HOC/Auxiliary/Auxiliary'
import Layout from './Layout/Layout'
import Footer from './Components/Footer/Footer'
import HomePage from './Pages/HomePage/HomePage'
import Dc from './Pages/Dc/Dc'
import ChalkPiece from './Pages/ChalkPiece/ChalkPiece'
import Unite from './Pages/UniteUs/Unite'
import About from './Pages/About/About'
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom'
import ScrollToTop from '../src/Components/Navigation/Scrolling/Scroll'
import VRV from './Pages/VRV/VRV';
import Wow from './Pages/Wow/Wow';
import Splash from './Components/SplashScreen/Splash';


const App=(props)=>{

  // const [loading,setLoading]=useState(true)

  // var x=window.screen.width;


  //   useEffect(()=>{      
  // if(x<=500)
  // setLoading(false)

  //       try {
  //           setTimeout(() => {
  //           setLoading(false)
  //           }, 10000)
  //         } catch (err) {
  //           setLoading(false)
  //         }

  //   },[])

  //   if(loading)
  //   return (
  //   <Splash/>
  //   )

      return ( <Router>
            <Switch>
              <ScrollToTop>
            <Aux>
              <Layout />
             
              <div>
              <Route path="/" exact component={HomePage}/>
              <Route path="/UniversityOfWashington" component={Dc}/>
              <Route path="/WOW" component={Wow}/>
              <Route path="/ChalkPiece" component={ChalkPiece}/>
              <Route path="/VRV" component={VRV}/>
              <Route path="/HomeUniteUs" component={Unite}/>
              <Route path="/About" component={About}/>
              </div>
                     
              <Footer/>
              </Aux>
              </ScrollToTop>
              </Switch>
              </Router>

      )    
  
 

}

export default App;
