import React from 'react'

import classes from './NavigationItems.module.css'

import {Link} from 'react-router-dom'


const navigationItems=(props)=>{
    return (
    <ul className={classes.NavigationItems}>

        <ul className={classes.NavigationItem}>
        <Link to="/">
        <li >Projects</li>
        </Link>
        
        <Link  to="/About" >
        <li >About</li>
        </Link>     
        
        <a 
        href="https://drive.google.com/file/d/1YwdH1uKf27Dwbmagq9dvv38pk1-ghrHL/view"
        target={"_blank"}
        >Resume</a>
       

        <a
        href="https://dribbble.com/K2kshitij" 
        className={classes.dribble}
        style={{
        fontSize:"35px",padding:"6px 10px"}}
        >
           dribble
        </a>
        </ul>
    </ul>)
}

export default navigationItems