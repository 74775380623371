import React from 'react'
import classes from './Footer.module.css'
import {Link} from 'react-router-dom'

const Footer=(props)=>(
    <div className={classes.main}>   
     <footer className={classes.Footer}>
         <div className={classes.logo}>
         <Link to="/" style={{textDecoration:"none"}}>
        <p>Kshitij Anand</p>
        </Link>
        </div>

        <div className={classes.items}>
        
        <ul className={classes.NavigationItem} >
        <Link to="/" style={{textDecoration:"none"}}>
        <a>Projects</a>
        </Link>
        
        <Link to="/About" style={{textDecoration:"none"}}>
        <a>About</a>
        </Link>
        
        <a href="https://drive.google.com/file/d/1YwdH1uKf27Dwbmagq9dvv38pk1-ghrHL/view" target={"_blank"}>Resume</a>
        <a href="https://www.linkedin.com/in/kshitij-anand-happy/" target={"_blank"}>Linkedin</a>    
        </ul>     
        </div> 
        
    </footer>
    </div>

)

export default Footer











