import React,{useState,useEffect} from 'react'
import Aux from '../../HOC/Auxiliary/Auxiliary'
import CaseStudy from '../../Components/CaseStudy/CaseStudy'
import classes from './HomePage.module.css'
import {Link} from 'react-router-dom'
import {TitleComponent} from '../../Components/TileComp/Tile'

const HomePage=(props)=>{


    const linkedin=()=>{
        const url= "https://www.linkedin.com/in/kshitij-anand-happy"
        window.open(url,'_blank')
    }


    return(
    <Aux>
       <TitleComponent title="Kshitij Anand | Product Designer"/>
        <div className={classes.header}>
        <h1>
        Hi! I am <span style={{color:"#FF732F"}} >Kshitij</span> , an Engineer turned Product Designer.
        </h1>
        <p>I am passionate about designing products that look good, feel better, and work best.
        </p>
    </div>
    <div className={classes.main}>
        <CaseStudy 
        title={"University of Washington"}
        subtitle={"Class Registration System"}
        description={"Registering for classes is confusing. How might we improve the class registration process to be more quick and intiutive?"}
        src="https://drive.google.com/uc?id=1DvDX-sDfGdPDQ50_iKA5-8NS13Uv_Dk1"
        to="/UniversityOfWashington"
        >   
        </CaseStudy>

        <CaseStudy 
        f="1"
       src="https://drive.google.com/uc?id=1dkWZJ3TXENG1Irv-cejhqb-jkURmlybN"
        title={"THE WOW BOX"}
        subtitle={"Android App Design"}
        description={"Designing the app that makes User Expierence - “ WOW”"}
        to="/Wow"
        ></CaseStudy>

        <CaseStudy 
        title={"VRV"}
        subtitle={"Mobile/Web Responsive Redesign"}
        description={"End-to-end overhaul of the current platform to increase the number and duration of Paid VRV memberships."}
        src="https://drive.google.com/uc?id=1WJLoqn9GdQDKB8zHG97XH3RaFBJ7JtbJ"
        to="/VRV"
        ></CaseStudy>

        <CaseStudy
        f="1"
        title="Home Unite Us"
        subtitle="WEB APP&SAAS"
        description="Home Unite Us Calendaring, building the internal scheduling system to optimize the process of placing homeless youth in safe temporary housing."
        src="https://drive.google.com/uc?id=1vBskCW6AdJUEaa0lQ_xktR5wxtgqLsJy"
        to="/HomeUniteUs"
        />
        
        {/* <CaseStudy
        title="ChalkPiece"
        subtitle="Engineering Design Process"
        description="Group of Designers,incorporating and addressing the pressing need for experience design to solve Social problems (with a commitment to spread design)"
        src="https://drive.google.com/uc?id=1dgisNlysD7XNHirascH4ZNV6ytc3bvvy"
        to="/ChalkPiece"
        /> */}
        <div className={classes.other}>         
            <p>Other works</p>
            <Link to="/ChalkPiece">
            <img src="https://drive.google.com/uc?id=1dgisNlysD7XNHirascH4ZNV6ytc3bvvy"/>
            </Link>
        </div>


        <div className={classes.HomePage} >
            <p>Questions about my portfolio?</p>
            <p>Happy to chat =)</p>
            <button onClick={linkedin}>Connect on Linkedin</button>
            <p2>TRUSTED BY CLIENTS LIKE</p2>
            <img src="https://drive.google.com/uc?id=1AqUwEGQLtM62YDHmn9O0CBt_ZS2CyxJL"></img>
        </div>
        </div>
        {/* <Footer/> */}
    </Aux>
)
}

export default HomePage











