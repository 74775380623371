import React, { Component } from "react";
import classes from "./Dc.module.css";
import Youtube from "./Youtube/Youtube";
import {TitleComponent} from '../../Components/TileComp/Tile'

class dc extends Component {
  render() {
    return (
      <div className={classes.main}>
        <TitleComponent title="University of Washington - Case Study"/>
        <div className={classes.Dc}>
           
          <p className={classes.header}>
            University of Washington Students Platforms
          </p>
          <img src="https://drive.google.com/uc?id=1YGXcaif1YR4RBghVh1Z_XHf2usnePbwu"></img>
        
          <p>
            <span className={classes.purpleHeading}>Intro </span>
            <br></br>
            <span className={classes.subHeading}>
              University student platforms are overwhelming so lets fix that.
            </span>
          </p>

         
          <p>
            University of Washington (UW) students’ website is similar to most
            university-integrated websites out there; it contains an abundance
            of both data and functions. Because of that, the website tends to
            look busy, cluttered, and disorganized. UW has fallen victim to
            information overload on every screen and as a result has neglected
            the user experience of the website itself. . <br />
            <br />
            The purpose for doing this project was to provide autonomy and
            confidence to students in utilizing their universitys’ student
            platform to its full potential by making the user flow more
            intuitive and hasslefree. With 6,000 newly enrolled students at UW
            every year and a few dozen staff to advise them, we also hope to
            ease the student’s dependence on University staffs for guidance for
            using the platform.
          </p>

          <img src="https://drive.google.com/uc?id=1HlcyAlm-xgzh1ORJZNrewfeZVIAlIBnE"></img>

          <p>
            <span className={classes.purpleHeading}> Design Process </span>
            <br></br>
            <span className={classes.subHeading}>
              Updated Double Diamond - Framework for innovation
            </span>
          </p>

          <div className={classes.day1}>
            <img src="https://drive.google.com/uc?id=1ANHGLeMsrG7P49QQoRxxpwEIDq1tUp7Z"/>
          </div>

          <p>
            For our two week sprint it was decided to implement the updated
            double diamond framework for this project. During the first week, I
            implemented basic concepts of the Research, Insight, Ideation, and
            Prototyping, test. The aim was to give the best result within a
            week, then keep iterating over the problem and solutions after user
            testing. to keep the process creative and non linear.
          </p>

          <p>
            <span className={classes.purpleHeading}> Week 1 Design Sprint</span>
            <br />
            <span className={classes.subHeading}>
              Day I - Lets dive into the problem we are trying to solve
            </span>
          </p>

          <div className={classes.day1}>
            <p>
              I started off the project creating assumptions about the user
              problems for the project which we formed from our analysis of the
              UW platform. Communicating early on with the product owner
              benefited in narrowing our focus to the 4 key pages within the UW
              platform. Which are:{" "}
            </p>
            <img src="https://drive.google.com/uc?id=1eCgM9RDh5RyItEbo5_LvsIS4JW2psmId" />
            <p>
              <span className={classes.subHeading}>
                User Problems: <br />
                Confusing Navigation
              </span>
              <br />
              Student have a really hard time navigating through the UW site,
              the resources are hard to find because of the lack of flow within
              the site.
              <br />
              <br />
              <span className={classes.subHeading}>Cognitive Load </span>
              <br />
              the pages contain too much information and is hard to digest the
              information without increasing user stress levels
              <br />
              <br />
              <span className={classes.subHeading}>Confusing language </span>
              <br />
              The site uses langauge that is hard for students to decipher and
              understand. The page titles are often labled in a confusing manner
              <br /> <br />
              <p style={{ textAlign: "center", fontWeight: "bold" }}>
                How Might We
              </p>
              <br />
              After understanding about the user problems and project scope, I
              moved on to form the problem statements. We conducted how might we
              as a team for each page and utilized card sorting come up with the
              most important How might we’s to focus on.
            </p>
          </div>

          <p>
            <span className={classes.purpleHeading}>Week 1 Design Sprint</span>{" "}
            <br />
            <span className={classes.subHeading}>
              {" "}
              Day II - Research and Discovery
            </span>
          </p>

          <p>
            Somtimes reinventing the wheel is not necessary in getting
            ispirations for possible solutions. I thought it was highly
            important to understand the positioning for UW’s platform in the
            academic platform market by anayzing other University student
            platforms. At the same time I looked into other apps outside of the
            academic sphere for inspiration.{" "}
          </p>

          <img src="https://drive.google.com/uc?id=1MjqAhSKXu81WEQoAAnAR9of4ANnwCTig" />
          <img src="https://drive.google.com/uc?id=1M1YrDLvblqgO48E3_wEDo8INNSkcgT5Z" />

          <p>
            <span className={classes.purpleHeading}> Week 1 Design Sprint</span>
            <br />
            <span className={classes.subHeading}>
              Day III - Sketching & Low-fidelity design
            </span>
          </p>

          <p>
            All the other academic insitutions we looked into had their own
            limitations, so I decided to pick and choose features that best
            suited us from the moodboard, and began sketching via Crazy 8’s
            method. We sketched soltuons that incorporated different features
            for each page into low fidelity wire frames.
          </p>

          <div className={classes.combo}>
            <img style={{ width: "40%" }} src="https://drive.google.com/uc?id=1zp3Kk9u1_aDqrj2uMDAbEDSGmKXnkT6O" />
            <img style={{ width: "40%" }} src="https://drive.google.com/uc?id=1IQjFEKCJSHF0JfZmL9HRGFyxCZiDJTbf"/>
          </div>
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            Above : Low-fidelity wireframes
          </p>

          <p>
            <span className={classes.purpleHeading}>Week 1 Design Sprint</span>
            <br />
            <span className={classes.subHeading}>
              Day IV & V - Prototyping and Testing
            </span>
          </p>

          <p className={classes.day1}>
            Based on our lo-fi design, I moved on to hi-fi design. For this I
            used the current color pallate, logo specification and typography as
            I wanted to create a cohesive design.
          </p>

          <p className={classes.subHeading}>The Moment of Truth</p>

          <div className={classes.day1}>
            <p>
              Lets Recap on our focus, I wanted to provide a simple organized
              platform for students to be able to easily navigate and use. So
              created different scenarious for the usability testers to complete
              for MyPlan, Account, Profile and resources. In order to eliminate
              test nerves and bias, users were informed that they not being
              tested. Some of the user questions that we asked were:
            </p>
          </div>

          <p>
            <span style={{ fontWeight: "bold" }}>
              {" "}
              My Plan &ensp;&ensp;&ensp;Scenario 1.
            </span>{" "}
            How can you plan for graudation based on your current track ?<br />
            <span style={{ fontWeight: "bold" }}>
              Finance &ensp;&ensp;&ensp;Scenario 2.
            </span>{" "}
            How do you make a partial payment for classes ?
            <br />
            <br />
            <br />
            <br />
            Hi fidelity and Prototype
            <br /> I made some scenarios with the prototypes so we can conduct
            usability testing the next day. These are the prototype for MyPlan
            page and Finance Page
          </p>

          <div className={classes.day1}>
            <Youtube link={"https://youtu.be/C99aw0CwF6s"} />

            <p style={{ textAlign: "center", fontSize: "18px" }}>
              <span style={{ fontWeight: "bold", color: "black" }}>
                Above :
              </span>{" "}
              MyPlan of UW Prototype
            </p>

            <Youtube link={"https://youtu.be/1_EwYWXieFU"} />

            <p style={{ textAlign: "center", fontSize: "18px" }}>
              <span style={{ fontWeight: "bold", color: "black" }}>
                Above :
              </span>{" "}
              Finance Page of UW Prototype
            </p>
          </div>

          <img src="https://drive.google.com/uc?id=10Dr-p5bv6Ar5GvLYSfQNfUdpCpNXWjEk" />

          <p>
            <span className={classes.purpleHeading}>Week 2 Design Sprint </span>{" "}
            <br />
            <span className={classes.subHeading}>
              Day I & II - Iterations and improvements{" "}
            </span>
            <br />
          </p>

          <div className={classes.day1}>
            <p>
              {" "}
              <span style={{ fontWeight: "bold", color: "black" }}>
                Turning painpoints into opportunities
              </span>{" "}
              <br />
              Based on the feedback of the users, we braisntormed a series of
              solutions and improvements for each of the user flows. I made sure
              to create opportunities from user painpoints as listed below
            </p>
          </div>

          <img src="https://drive.google.com/uc?id=1CNtjWrEVqbvtZokrNWi2znuymuwJ0n3Y" />
          <img src="https://drive.google.com/uc?id=1N_eK96JwH9ehKc75pUStcf-5WYQcktaV" />

          <p>
            <span className={classes.purpleHeading}> Week 2 Design Sprint</span>
            <br />
            <span className={classes.subHeading}>
              Day III - User Testing part 2
            </span>
          </p>

          <div className={classes.day1}>
            <p>
              After all the iterations were conducted again it was decided to
              retest our design, to see if we were successfully able to provide
              solutions to their stated painpoints. We recruited 5 additonal
              users and kept the same user scenarios. The results are listed
              below:
            </p>
          </div>

          <img src="https://drive.google.com/uc?id=125EY_PG1LNdSg5_rWoE3tb8qmO_Hp3gz" />

          <p>
            <span className={classes.purpleHeading}> Week 2 Design Sprint</span>
            <br />
            <span className={classes.subHeading}>Day IV - The Last Call!</span>
          </p>
          <div className={classes.day1}>
            <p>
              After assesing the user feedbacks we once again cleaned up our
              designs. We also made sure that our design guidelines were closely
              followed for the 4 different focuses to ensure consistency. The
              prototypes were tested to make sure all the elements functioned
              properly before handing off to the development team.
              <br />
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                {" "}
                Did we achieve our project goal ?{" "}
              </span>
              <br />
              <br />
              At the start of the project our goal was to ease the student’s
              dependence on University staffs for guidance for using the
              platform.
              <br />
              <br />
              In order to reach our goal, a degree requirement page was added
              which will provide students a clear guidance on which courses to
              take to be on track for graduation easing the need of a counselor.
              we also added an editable function to the class cards for MyPlan,
              allowing students to easily customize thier class plan for
              upcoming semesters. With the left expandable menu and searchable
              resources, I was able to provide a sense of clear hierchy and
              organization to the site.
            </p>
          </div>
          <p className={classes.finalP}>Final Prototypes </p>

          <div className={classes.day1}>
            <p className={classes.day1} style={{ fontSize: "18px" }}>
              MyPlan - Scenario 1{" "}
            </p>
            <Youtube link={"https://youtu.be/2iIRwLzMo5I"} />
            <p style={{ textAlign: "center", fontSize: "18px" }}>
              <span style={{ fontWeight: "bold", color: "black" }}>
                Above :
              </span>{" "}
              My Plan Final Prototype
            </p>

            <Youtube link={"https://youtu.be/8h21B7UMN34"} />
            <p style={{ textAlign: "center", fontSize: "18px" }}>
              <span style={{ fontWeight: "bold", color: "black" }}>
                Above :
              </span>{" "}
              Resources Final Prototype
            </p>
          </div>

          <p className={classes.purpleHeading}>Moving Foward</p>

          <p className={classes.day1}>
            {" "}
            <span style={{ fontWeight: "bold", color: "black" }}>
              The Story Continues...{" "}
            </span>{" "}
            <br />
            After solving the problems I faced with MyPlan, Fianance, I will
            continously try to improve the functionalities of the UW platform,
            as no design is ever perfect.
            <br />
            <br />
            For our roadmap, my team is planning on working on: collaborating
            with counselors and professors inorder to create more value for not
            only the students but for the staff as well. We hope to work more on
            the Degree requirement page, as students had a bit of a stuggle with
            this page. We also hope to do a 3rd round of testing for the
            protoype.{" "}
          </p>
          </div>
        </div>
      
    );
  }
}

export default dc;
