import React,{Component} from 'react'
import Aux from '../HOC/Auxiliary/Auxiliary'
import Toolbar from '../Components/Navigation/Toolbar/Toolbar'
import SideDrawer from '../Components/Navigation/SideDrawer/SideDrawer'


class layout extends Component{
    state={
        showSideDrawer:false
    }
    
    SideDrawerClosedHandler=()=>{
        this.setState({showSideDrawer:false})
    }
    
    sideDrawerToggleHandler=()=>{
    
        this.setState((prevState)=>{
            return {showSideDrawer:!prevState.showSideDrawer}
        })
    
    }
    
    
    render()
    {
        return(
            <Aux>

            <Toolbar drawerToggleClicked={this.sideDrawerToggleHandler}/>
            <SideDrawer 
            open={this.state.showSideDrawer} 
            closed={this.SideDrawerClosedHandler}/>
            

            </Aux>
        )
    }



}

export default layout