import React from "react";
import classes from "./../Dc/Dc.module.css";
import MediaQuery from 'react-responsive';
import {TitleComponent} from '../../Components/TileComp/Tile'

const Hobby=(props)=>{
    return (
        <div className={classes.aboutBox}>
            <img src={props.src}/>
            <div style={{marginLeft:"5%",width:"65%",alignSelf:"center"}}>
                <h1>{props.title}</h1>
                <p>{props.desc}</p>
            </div>
        </div>
    )
}

const about = (props) => {

    const clicked=()=>{
        const url="https://www.linkedin.com/in/kshitij-anand-happy/"
        window.open(url)
    }

    const resume=()=>{
      const url="https://drive.google.com/file/d/1YwdH1uKf27Dwbmagq9dvv38pk1-ghrHL/view"
      window.open(url)
  }

  return (
    <div>
      <TitleComponent title="About Me | Kshitij Anand"/>
      <div>
        
      <MediaQuery query="(min-device-width: 500px)">
        <img
        className={classes.headerPic}
          src="https://drive.google.com/uc?id=1cfv-NWlUQckUh3U9IRaNc581ksZFqvpd"
          style={{ maxWidth: "100%" }}
        />
        </MediaQuery> 

        <MediaQuery query="(max-device-width: 500px)">
        <img
        className={classes.headerPic}
          src="https://drive.google.com/uc?id=17Aj0s-Mf7dSrdo2w1KQWrvWFwMO_SwUQ"
          style={{ maxWidth: "100%" }}
        />
        </MediaQuery> 
        
      </div>
      <div className={classes.main} style={{marginTop:"0%"}}>
        <div className={classes.Dc}>
          <h1 className={classes.Abouth2}>
        <span className={classes.Abouth1}>I am Kshitij Anand </span>, I <span className={classes.Abouth1}>lead</span> and <span className={classes.Abouth1}>design</span> products that people love .
          </h1>

          <p>
            Hi! Thanks for stopping by. I’m currently a UX Designer working at
            Hack For LA. I am also working for a Nonprofit named Chalkpiece
            where I teach design to teenagers.I'm an end-to-end designer who
            loves solving challenging problems. Curious by nature, I love
            connecting with and understanding people. I get jazzed leading
            in-depth user research and brainstorming solutions. I'm always
            trying to grow and learn something new.
          </p>

          <h1 style={{lineHeight:"40px"}}>I’m never bored...</h1>
          <p>
            In my free time, my curiosity has lead me to discover a plethora of
            hobbies. You’ll have to contact me to find out what they are. But!
            I’ll tell you some of my hobby goals:
          </p>
        
        
        <div className={classes.Aboutquad}>
            
        <div style={{display:"flex",flexWrap:"wrap",alignSelf:"center"}}>
        <Hobby 
        src="https://drive.google.com/uc?id=1ojLm7Xzk6prD45O9vK8_0M9vg23-MtdI"
        title="Love for Writing"
        desc="I begin writing on Quora from highschool .I post content on Quora in German ,English and Hindi."
        />

        <Hobby 
        src="https://drive.google.com/uc?id=1c5HuZ7bp9QSMd6fzkxLgB6Z8dLLWUOEz"
        title="Play one song"
        desc="I’m simultaneously learning how to play the piano, drums, bass, and guitar. Music Theory anyone?"
        />

       <Hobby 
        src="https://drive.google.com/uc?id=1wI38jeZNFa1W2G7scMdXmhvr-pYPE05B"
        title="Read a book per month"
        desc="I’m currently reading The Rise and Fall of the Third Reich by William L. Shirer."
        />
        
        <Hobby  
        src="https://drive.google.com/uc?id=11c8atdcti4TcQZVujo03sKwQIFrvxjM0"
         title="Meditate everyday"
        desc="Try to meditate everyday!
         "/>
        
        </div>

        </div>
        
          <p>
            I’m constantly learning something new to better myself and my
            understanding of everything. To live a life led by curiosity is a
            life worth living.
          </p>
        
        <div style={{textAlign:"center"}}>
            <p>Want to learn more about my skills and experience?</p>
            <button className={classes.caseButton} onClick={resume}>Download Resume</button>

            <p>Or curiosity about my other hobbies?</p>
            <button className={classes.caseButton} onClick={clicked} style={{marginBottom:"5%"}}>Connect on Linkedin</button>

        </div>

        </div>
      </div>
    </div>
  );
};

export default about;
