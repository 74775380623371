import React from 'react'
import classes from './SideDrawer.module.css'
import Logo from '../../Logo/Logo'
import Aux from '../../../HOC/Auxiliary/Auxiliary'
import NavigationItems from '../NavigationItems/NavigationItems'
import Backdrop from '../../UI/Backdrop/Backdrop'

const SideDrawer=(props)=>{
    let attachedClasses =[classes.SideDrawer,classes.Close]

    if(props.open){
        attachedClasses=[classes.SideDrawer,classes.Open]
    }

    return (
        <Aux>
            <Backdrop show={props.open} clicked={props.closed}/>
           <div className={attachedClasses.join(' ')}>
                <nav>
                    <NavigationItems/>
                </nav>
        </div> 

        </Aux>
    )
}

export default SideDrawer