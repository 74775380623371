import React from "react";
import classes2 from "./../Dc/Dc.module.css";
import classes3 from "./../ChalkPiece/ChalkPiece.module.css";
import classes from "./Unite.module.css";
import {TitleComponent} from '../../Components/TileComp/Tile'


const FourImages = (props) => {
  return (
    <div className={classes2.FourImages}>
      <div> 
        <img src={props.src1} />
      </div>

      <div style={{ paddingTop: "5%"}}>
        <img src={props.src2} />
      </div>

      <div style={{ paddingTop: "10%" }}>
        <img src={props.src3} />
      </div>

      <div style={{ paddingTop: "15%"}}>
        <img src={props.src4} />
      </div>
    </div>
  );
};

const unite = (props) => {

  const clicked=()=>{
    const url="https://www.figma.com/proto/dzKNEgQXi23itBGQmIcI4r/Home-Unite-Us?page-id=0%3A1&node-id=12%3A4&viewport=846%2C308%2C0.03125&scaling=scale-down"
    window.open(url)
  }

  return (
    <div>
      <TitleComponent title="Home Unite Us - Case Study"/>
      <div >
      <img
         className={classes.header}
        src="https://drive.google.com/uc?id=1ny7_n_MvIX0DNkrcE8rgugMo0F8vi57N"/>
      </div>
        <div className={classes2.main}>

        <div className={classes2.Dc}>

          <h1 className={classes2.purpleHeading}>Intro</h1>
          <h1 className={classes2.subHeading}>
            The scourge of homelessness is an epidemic in America .
          </h1>
          <p>
            Home Unite Us is a volunteer-led project within Hack for LA , I am
            working with community non-profits who have a Host Home initiative
            to develop a workflow management tool to make the process scaleable
            (across all providers), reduce institutional bias, and effectively
            capture data.
            <br />
            <br />
            Host Home programs are centered around housing young people, 18 - 25
            years old. Their approach focuses on low-cost, community-driven
            intervention by matching a willing host with a guest or group of
            guests, providing a stable housing environment for youths who are
            experiencing homelessness and seeking stable housing.
            <br />
            <br />I am the part of the Calendaring Team.The purpose of this
            service is to help participants manage their scheduled meetings, a
            key component of the matching process.
          </p>

          <img src="https://drive.google.com/uc?id=10Xrnw0RT9BXVkMfLHpXi9Zx8zjOJAE1T" />

          <h1 className={classes2.purpleHeading}>Goal</h1>
          <p>
            Increase success in moving people through the matching process.
            <br />
            Prevent candidate drop off for hosts and guests by making a smoother
            experience.
            <br />
            Increase the speed and efficiency of caseworkers in the number of
            cases they can close.
          </p>

          <h1 className={classes2.purpleHeading}>Pain Points</h1>
          <p>
            It’s difficult for organizations to deal with the high number of
            applicants while using their current paper system.
            <br />
            Caseworkers need a more streamlined approach to effectively schedule
            meetings with participants.
          </p>

          <h1 className={classes2.purpleHeading}>What already exist</h1>

          <img src="https://drive.google.com/uc?id=1n_7iv0oFGLyzueGN_yhERNYDSXsZDKrT" />
          <p>
            We looked at other calendar services and how they managed their
            scheduling, like Calendly, Google Calendar, Doodle, and more. We
            basically want to see here what other applications are doing, and
            explore what interactions could be adapted to our product as well as
            what was out of scope for the current requirements.
          </p>

          <h1 className={classes2.purpleHeading}>User Flow</h1>
          <p>
            After identifying the problem, I had to understand the general task
            flows to account for. There were four distinct ones to tackle.
            <br />
            <br />
            One of the main intricacies was the matching process where we needed
            to facilitate three parties in a singular meeting. This is a major
            challenge from both a design and development perspective.
          </p>
          <img src="https://drive.google.com/uc?id=1iD8DrN6FHMIMIA3n-McWwHmUirplUisw" />

          <h1 className={classes2.purpleHeading}>Wireframing</h1>
          <img src="https://drive.google.com/uc?id=14XBQFIB3lEIMtyYm-XtFbRKjbT-C5rET" />

          <h1 className={classes2.purpleHeading}>Onboarding Screens :</h1>

          <FourImages
            src1="https://drive.google.com/uc?id=103eZh9GqHu0qcuGT77UvcJXA0Ex-56VL"
            src2="https://drive.google.com/uc?id=1HSVIoPlyA-rVYiLvg5b3Wsv51-jHNimk"
            src3="https://drive.google.com/uc?id=1HSVIoPlyA-rVYiLvg5b3Wsv51-jHNimk"
            src4="https://drive.google.com/uc?id=1lwv4mzLDyuj18cIrlFs8xQkpfgPyzlLk"
          />

          <h1 className={classes2.purpleHeading}>Other Screens :</h1>

          <FourImages
            src1="https://drive.google.com/uc?id=1a3eKtFu_bKFMzSIGVHbSvyeKDvr-96O7"
            src2="https://drive.google.com/uc?id=1qQXtIV25XI73M0tq_Bve4wQa0_ma36KI"
            src3="https://drive.google.com/uc?id=1FCED5Zz9nf_3txeZCdlXDxbaIj4YqYl-"
            src4="https://drive.google.com/uc?id=1qcVJjl4p2dPaEFFTxmaccYpgA3mBGAJ_"
          />

        <div className={classes3.pic2}>
          <button className={classes3.button1} 
          style={{background:"#08BCF5",borderColor:"#08BCF5"}}
          onClick={clicked}
          >
          <span> VIEW MY WORK HERE </span> 
          </button>
        </div>

          <h1 className={classes2.purpleHeading}>Reflection:</h1>
          <p>
            Working so closely with developers has continued to be rewarding for
            me. We were quickly able to understand technical limitations and
            design around them more effectively. However, it’s important to test
            frequently during the design process, and I feel we could have done
            more earlier to facilitate that.
          </p>
        </div>
      </div>
    </div>
  );
};

export default unite;
